import { config } from '../../js/config'
import { Api } from '../../service/Api'
const state = {}

const getters = {}

const actions = {
	add({ commit }, data ){
		return new Api().add(config.socialThemeAdd, data)
	},
    get({ commit }, id ){
        return new Api().detail(config.socialThmeGet, {id: id})
    },
    list({ commit }, data){
        return new Api().getList(config.socialThemeList,data)
    },
    del({ commit }, data){
        return new Api().delete(data)
    },
    recommend({ commit }, data){
        return new Api().post(config.socialThemeRecommend,data)
    },
	updateStatus({ commit }, data){
		return new Api().setStatus(data.model,data.id,data.status)
	},
    sort({ commit }, data){
        return new Api().add(config.setSocialThemeSort,data)
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
