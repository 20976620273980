import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    getCourseList({commit},data){
        return (new Api()).getList(config.getCourseList,data)
    },
    getCourseDetail({commit},id){
        return (new Api()).getList(config.getCourseDetail,{id:id})
    },
    bindCourse({commit},data){
        return (new Api()).getList(config.bindCourse,data)
    },
    createCourse({commit},data){
        return (new Api()).getList(config.addCourse,data)
    },
    courseList({commit},data){
        return (new Api()).getList(config.courseList,data)
    },
    setCourseStatus({commit},data){
        return (new Api()).setStatus('CourseModel',data.id,data.status)
    },
    delCourse({commit},data){
        return (new Api()).del(config.delCourse,data)
    },

    //创建章节
    addChapter({commit},data){
        return (new Api()).add(config.addChapter,data)
    },
    //创建章节
    editChapter({commit},data){
        return (new Api()).add(config.addChapter,data)
    },
    //获取章节列表
    getChapter({commit},data){
        return (new Api()).getList(config.getChapter,data)
    },

    //获取章节
    getChapterList({commit},data){
        return (new Api()).getList(config.getChapterList,data)
    },

    //创建课时
    addClass({commit},data){
        return (new Api()).add(config.addClass,data)
    },
    //创建课时
    getClassDetail({commit},data){
        return (new Api()).add(config.getClassDetail,data)
    },

    //设置排序
    setSort({commit},data){
        return (new Api()).add(config.setSort,data)
    },
    setTheStatus({commit},data){
        return (new Api()).setStatus(data.model,data.id,data.status)
    },
    //获取课堂学员
    getCourseStudent({commit},data) {
        return (new Api()).getList(config.getCourseStudent,data)
    },
    //获取课程文件
    getCourseFile({commit},data) {
        return (new Api()).getList(config.getCourseFile,data)
    },
    //添加课程
    addCourseFile({commit},data){
        return (new Api()).add(config.addCourseFile,data)
    },
    setCourseFileStatus({commit},data){
        return (new Api()).setStatus('CourseFileModel',data.id,data.status)
    },
    delCourseFile({commit},id){
        return (new Api()).commonDelete('CourseFile',id)
    },
    setCourseUserStatus({commit},data){
        return (new Api()).setStatus('UserCourseModel',data.id,data.status)
    },
    //获取学习记录
    getUserStudyLog({commit},data)
    {
        return (new Api()).getList(config.getUserStudyLog,data)
    },
    //添加学员到课堂
    addCourseStudent({commit},data)
    {
        return (new Api()).add(config.addCourseStudent,data)
    },
    //发送课堂通知
    sendCourseNotify({commit},data){
        return (new Api()).add(config.sendNotify,data)
    },
    //设置作业数量
    setWorkNumber({commit},data)
    {
        return (new Api()).add(config.setWorkNumber,data)
    },

    getCourseWxCode({commit},data){
        return (new Api()).add(config.getCourseWxCode,data)
    }

}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
