import { Api } from "@/service/Api";
import { config } from "@/js/config";

const state = () => ({});

const getters = {};

const actions = {
  enterpriseList({ commit }, data) {
    return new Api().getList(config.enterpriseList, data);
  },
  addEnterprise({ commit }, data) {
    return new Api().getList(config.addEnterprise, data);
  },
  setEnterpriseDefault({ commit }, data) {
    return new Api().getList(config.setEnterpriseDefault, data);
  },
  setEnterpriseStatus({ commit }, data) {
    return new Api().getList(config.setEnterpriseStatus, data);
  },
  getEnterprise({ commit }, data) {
    return new Api().getList(config.getEnterprise, data);
  },
  delEnterprise({ commit }, data) {
    return new Api().getList(config.delEnterprise, data);
  },
  detailEnterprise({ commit }, data) {
    return new Api().getList(config.detailEnterprise, data);
  },
  addEnterpriseMchid({ commit }, data) {
    return new Api().getList(config.addEnterpriseMchid, data);
  },
  getEnterpriseMchid({ commit }, data) {
    return new Api().getList(config.getEnterpriseMchid, data);
  },
  delEnterpriseMchid({ commit }, data) {
    return new Api().getList(config.delEnterpriseMchid, data);
  },
  selectEnterprise({ commit }, data) {
    return new Api().getList(config.selectEnterprise, data);
  },
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
