import { config } from '../../js/config'
import { Api } from '../../service/Api'

const state = {}

const getters = {}

const actions = {
	getAppUpdate({ commit }, data ){
		return new Api().add(config.getAppUpdate, data)
	},
	saveAppUpdate({ commit }, data){
		return new Api().add(config.saveAppUpdate,data)
	}
}

const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
