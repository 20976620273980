import {Api} from "../../service/Api";

const state = () => ({

})

const getters = {

}

const actions = {
    //设置作业数量
    setScheduling({commit},data){
        return (new Api()).add(config.setScheduling,data)
    },
    getScheduling({commit},data){
        return (new Api()).add(config.getScheduling,data)
    },
    setSchedulingTeacher({commit},data)
    {
        return (new Api()).add(config.setSchedulingTeacher,data)
    },
    addCourseSchedulingCourse({commit},data)
    {
        return (new Api()).add(config.addCourseSchedulingCourse,data)
    },
    getCourseSchedulingCourse({commit},data)
    {
        return (new Api()).add(config.getCourseSchedulingCourse,data)
    },
    getCourseSchedulingTeacherList({commit},data)
    {
        return (new Api()).add(config.getCourseSchedulingTeacherList,data)
    },
    getSchedulingDetail({commit},id)
    {
        return (new Api()).add(config.getSchedulingDetail,{id:id})
    },
    setSchedulingLog({commit},data){
        return (new Api()).add(config.setSchedulingLog,data)
    },
    courseSchedulingNotify({commit},id){
        return (new Api()).add(config.courseSchedulingNotify,{id:id})
    },
    batchAddScheduling({commit},data){
        return (new Api()).add(config.batchAddScheduling,data)
    },
    setSchedulingStudent({commit},data){
        return (new Api()).add(config.setSchedulingStudent,data)
    },
    getSchedulingStudent({commit},data){
        return (new Api()).add(config.getSchedulingStudent,data)
    },
    getSchedulingLog({commit},data){
        return (new Api()).add(config.getSchedulingLog,data)
    },
    setCourseStatus({commit},data){
        return (new Api()).setStatus('CourseScheduling\\CourseSchedulingCourseModel',data.id,data.status)
    },

}

const mutations = {

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
